<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">精品课程</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">课程分类设置</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:5px">
            <div title="分类名称" class="searchboxItem ci-full">
              <span class="itemLabel">分类名称:</span>
              <el-input
                v-model="name"
                type="text"
                size="small"
                clearable
                placeholder="请输入分类名称"
              />
            </div>
            <div class="btnBox" style="margin-left:20px">
              <el-button
                style="margin-left:20px"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
              <el-button class="bgc-bv" round @click="handleCreate('add')"
                >新增分类</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              @cell-mouse-enter="M_Enter"
              @cell-mouse-leave="m_leave"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="分类名称"
                align="left"
                prop="name"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="课程数量（个）"
                align="left"
                prop="courseCount"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="排序"
                align="center"
                min-width="100"
                prop="order"
              >
                <!--  -->
                <template slot-scope="scope">
                  <div v-if="!scope.row.orderShow">
                    <span> {{ scope.row.order }}</span>
                    <i
                      class="el-icon-edit"
                      v-show="scope.row.showInput"
                      @click="changeOrder(scope.row)"
                    ></i>
                  </div>
                  <el-input
                    v-else
                    v-model="scope.row.order"
                     maxlength="9"
                    size="small"
                    @blur="
                      () => {
                        editRowOrder(scope.row);
                      }
                    "
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column label="上传时间" align="center" min-width="100">
                <!--  -->
                <template slot-scope="scope">
                  {{ scope.row.createTime }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="140px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="handleEdit('edit', scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    :disabled="scope.row.courseCount >0"
                    style="padding:0px 5px"
                    size="mini"
                    @click="handleDel(scope.row.courseTypeId)"
                    >删除</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog :title="title" :visible.sync="DialogVisible" width="50%" center>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        @close="doClose"
      >
        <el-form-item label="分类名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            size="small"
            maxlength="8"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="order">
          <el-input
            v-model="ruleForm.order"
            size="small"
            maxlength="9"
            oninput="value=value.replace(/[^\d]/g,'')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div style="display:flex;justify-content:center">
        <el-button @click="doClose">取消</el-button>
        <el-button class="bgc-bv" @click="submitForm('ruleForm')"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "course_jp/classificationList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    //
    let validateOrder = (rule, value, callback) => {
      if (value.trim() != "") {
        if (!reg.test(val)) {
          callback(new Error("排序应为大于0的正整数"));
        } else {
          callback();
        }
      } else {
        callback(new Error("请输入排序值"));
      }
    };
    return {
      DialogVisible: false,
      title: "",
      name: "",
      showInput: false,
      ruleForm: {
        name: "",
        order: "",
      },
      stu: "add",
      rules: {
        name: [
          { required: true, message: "分类名称不能为空", trigger: ["blur"] },
          { min: 1, max: 8, message: "分类名称不超过8个字", trigger: "blur" },
        ],
        order: [{ required: true, message: "排序不能为空", trigger: ["blur"] }],
      },
    };
  },
  computed: {},
  created() {
    this.getTableHeight();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.name) {
        params.name = this.name;
      }
      this.doFetch({
        url: "/biz/ec/course/type/listPage",
        params,
        pageNum,
      },true,2);
    },
    // 新增
    handleCreate(stu) {
      this.stu = stu;
      this.title = "新增课程分类";
      this.DialogVisible = true;
    },
    // 修改
    handleEdit(stu, row) {
      this.stu = stu;
      this.title = "修改课程分类";
      this.DialogVisible = true;
      this.ruleForm = {
        ...this.ruleForm,
        ...row,
      };
    },
    //确认
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.doAjax();
        }
      });
    },
    doAjax() {
      let params = {
        name: this.ruleForm.name,
        order: this.ruleForm.order,
      };
      if (this.stu == "edit") {
        params.courseTypeId = this.ruleForm.courseTypeId;
      }
      this.$post(
        this.stu == "add"
          ? "/biz/ec/course/type/insert"
          : "/biz/ec/course/type/modify",
        params, 3000, true, 2
      )
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.doClose();
            this.getData();
          }
        })
        .catch((err) => {
          return;
        });
    },
    doClose() {
      this.DialogVisible = false;
      this.$refs["ruleForm"].resetFields();
      this.ruleForm = {};
    },
    // 删除
    handleDel(courseTypeId) {
      this.$confirm("是否确认删除该分类名称？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/biz/ec/course/type/delete", { courseTypeId }, 3000, true, 2).then((res) => {
            if (res.status == "0") {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getData(-1);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleListDataBack(data) {
      this.tableData = [];
      data.map((el) => {
        el.showInput = false;
        el.orderShow = false;
        this.tableData.push(el);
      });
    },
    // 鼠标进入事件
    M_Enter(row, column, cell, event) {
      console.log(row, column, cell, event);
      if (column.label == "排序") {
        row.showInput = true;
      }
    },
     // 鼠标离开事件
    m_leave(row, column, cell, event) {
      row.showInput = false;
    },
    // 点击笔
    changeOrder(row) {
      ;
      row.orderShow = !row.orderShow;
    },
    //输入框失去焦点 保存修改
    editRowOrder(row) {
      const params = {
        name: row.name,
        order: row.order,
        courseTypeId: row.courseTypeId,
      };
      this.$post("/biz/ec/course/type/modify", params, 3000, true, 2).then((res) => {
        ;
        this.$message({
          type:'success',
          message:'修改成功'
        })
        this.getData(-1);
      });
    },
    //
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
</style>
